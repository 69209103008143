import _get from 'lodash/get'
import { baseRequest } from './config.js'
import { ENV } from '../constants/env'

const brandSlug = ENV.BRAND_SLUG
const isWindowDefined = typeof window !== 'undefined'

export const eventAdapter = (data) => {
  const event = {
    ...data,
    ..._get(data, 'attributes', {}),
    self: _get(data, 'relationships.self.data', {}),
    owner: _get(data, 'relationships.owner.data', {}),
  }
  delete event.attributes
  delete event.relationships
  return event
}

const eventsAdapter = (events) => {
  const adaptedEventsData = events.map((item) => {
    const event = { ...item.event, venue: item.venue }
    return event
  })
  return adaptedEventsData
}

export const getEvents = async (slug = brandSlug, offset = 0, limit = 10, total) => {
  const params = {
    offset,
    limit,
    compact: 'event-list',
  }

  // IF offset = 0 then dont send total, otherwise send total to avoid extra calculations in the backend
  if (offset !== 0) {
    params.total = total
  }
  try {
    const res = await baseRequest.get(
      `events/${slug}?format=json&client=mana&sectionType=upcoming`,
      { params }
    ) //https://test.ticketfairy.com brand
    const data = _get(res, 'data.events', [])
    return eventsAdapter(data)
  } catch (e) {
    console.log('getEvents error', e)
    throw e
  }
}

export const getEventsV2 = async () => {
  try {
    const res = await baseRequest.get(`api/v1/events/current`)
    const data = _get(res, 'data', [])
    return data
  } catch (e) {
    console.log('getEvents error', e)
    throw e
  }
}

export const getEvent = async (slug, previewKey) => {
  try {
    const headers = {}
    if (isWindowDefined) {
      headers['authorization-guest'] = window.localStorage.getItem('auth_guest_token')
    }

    const res = await baseRequest.get(`/event/${slug}/?format=json&client=mana`, {
      params: {
        pk: previewKey,
      },
      headers
    })
    const data = _get(res, 'data')
    return data
  } catch (e) {
    console.log('getEvent error', e)
    throw e
  }
}

export const getProtectedEvent = async (password, id) => {
  try {
    const res = await baseRequest.post(`api/v1/event/${id}/authenticate`, {
        attributes:{
          data:{
            password
          }
        }
    })
    const data = _get(res, 'data')
    const headers = res.headers
    const session = headers["authorization-guest"]
    if (isWindowDefined && session) {
      window.localStorage.setItem('auth_guest_token', session)
    }
    return data
  } catch (e) {
    console.log('getProtectedEvent error', e)
    throw e
  }
}

export const getEventSliderData = async (slug = brandSlug) => {
  try {
    const res = await baseRequest.get(
      `events/${slug}?format=json&client=mana&isSlider=true`
    )
    const data = _get(res, 'data.events', [])
    return data
  } catch (e) {
    console.log('getEventSliderData error', e)
    throw e
  }
}

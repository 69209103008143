import React, { useState } from 'react'
import { LoginModal, ForgotPasswordModal, useCookieListener } from 'tf-checkout-react'
import { ToastContainer } from 'react-toastify';
import Header, { X_TF_ECOMMERCE } from '../header'
import Footer from '../footer'
import Logo from '../../assets/images/mana_footer.png'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import './style.scss'
import { getCookieByName } from '../../utils/cookies'
import 'react-toastify/dist/ReactToastify.css';

setTfCheckoutReactConfigs()

const Layout = ({ children }) => {
  const [showLogin, setShowLogin] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const isWindowDefined = typeof window !== 'undefined'
  const [isLogged, setIsLogged] = useState(
    isWindowDefined ? !!getCookieByName(X_TF_ECOMMERCE) : false
  )

  useCookieListener(X_TF_ECOMMERCE, value => setIsLogged(Boolean(value)))
  
  const onClose = () => {
    setShowLogin(false)
  }

  const handleOnLogin = (e) => {
    setShowLogin(false)
    setIsLogged(true)
    if(isWindowDefined){
      if(window.location.pathname?.includes('billing')){
        window.location.reload();
      }
    }
  }

  return (
    <>
      <Header setShowLogin={setShowLogin} setIsLogged={setIsLogged} isLogged={isLogged}/>
      <main className='main-layout-content'>
        {showLogin && !isLogged && (
          <LoginModal
            logo={Logo}
            onClose={onClose}
            onLogin={handleOnLogin}
            onForgotPassword={() => {
              setShowLogin(false)
              setShowResetPasswordModal(true)
            }}
            showForgotPasswordButton
          />
        )}
        {showResetPasswordModal && (
          <ForgotPasswordModal
            onClose={() => {
              setShowResetPasswordModal(false)
            }}
            onLogin={() => {
              setShowResetPasswordModal(false)
              setShowLogin(true)
            }}
            onForgotPasswordSuccess={() => {
              setShowResetPasswordModal(false)
              setShowLogin(true)
            }}
          />
        )}
        {children}
      </main>
      <ToastContainer />
      <Footer />
    </>
  )
}

export default Layout
import { CircularProgress } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import cookie from 'cookie'
import _get from 'lodash/get'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { PreRegistration, PreRegistrationInformations, TicketsContainer } from 'tf-checkout-react'

import Logo from '../../../assets/images/mana_footer.png'
import NoDataImg from '../../../assets/images/no_image.png'
import AccessControl from '../../../components/access/AccessControl'
//import './style.scss'
import PasswordProtected from '../../../components/access/PasswordProtected'
import Error from '../../../components/error'
import Head from '../../../components/head'
import Layout from '../../../components/layout'
import { ENV } from '../../../constants/env'
import { getEvent } from '../../../http_service'
import { createMarkup } from '../../../utils/common'
import { setTfCheckoutReactConfigs } from '../../../utils/tf-checkout-config'
import { NotFoundPage } from "../../404"
import { ShareButtons } from '../../../components/events/ShareButtons'

export const isWindowDefined = typeof window !== 'undefined'

const handleCartSuccessRedirect = (
  skip_billing_page,
  event_id = '',
  hash,
  total,
  hasAddOn,
) => {
  if (hasAddOn) {
    if (isWindowDefined) {
      window.location.href = `/addon?event_id=${event_id}`
    }
  } else if (skip_billing_page) {
    if (isWindowDefined) {
      window.localStorage.setItem('checkoutData', JSON.stringify({ hash, total }))
      window.location.href = `/billing/checkout?event_id=${event_id}`
    }
  } else {
    event_id && isWindowDefined
      ? window.location.assign(`/billing/billing-info?event_id=${event_id}`)
      : isWindowDefined && window.location.assign(`/billing/billing-info`)
  }
}

const handlePreRegistrationCompleteRedirect = (confirmationData, eventId) => {
  const {
    attributes: { hash },
  } = confirmationData.data
  isWindowDefined && window.localStorage.setItem('pre-registration-hash', hash)
  isWindowDefined && window.location.assign(`/pre-registration-confirmation?event_id=${eventId}`)
}

const getQueryPromoCode = () => {
  if (isWindowDefined) {
    const params = new URL(window.location.href).searchParams
    const promoCode = params.get('r')
    return promoCode || ''
  }

  return ''
}

const onLoginSuccess = () => {
  if (isWindowDefined) {
    const event = new window.CustomEvent('tf-login')
    window.document.dispatchEvent(event)
  }
}

const formatDate = (startDate, endDate, formatted_date) => {
  const format = 'MMM DD, YYYY'
  const formattedStartDate = moment(startDate).format(format).toLocaleString()
  const formattedEndDate = moment(endDate).format(format).toLocaleString()
  const isSameDay = moment(formattedStartDate).isSame(formattedEndDate, 'day')

  if (isSameDay) {
    return formatted_date
  } else {
    return formattedStartDate.concat(' - ', formattedEndDate)
  }
}

setTfCheckoutReactConfigs()

const EventPage = ({ serverData }) => {
  const [event, setEvent] = useState({})
  const [venue, setVenue] = useState({})
  const [tickets, setTickets] = useState([])
  const [slug, setSlug] = useState('')
  const [pk, setPk] = useState('')
  const [loading, setLoading] = useState(false)

  const eventSlug = serverData?.slug
  const eventId = serverData?.eventId

  const isPasswordProtected = serverData?.error && serverData?.message?.status === 401

  useEffect(() => {
    setEvent(_get(serverData, 'event.event', {}))
    setVenue(_get(serverData, 'event.venue', {}))
    setSlug(_get(serverData, 'slug', ''))
    setPk(_get(serverData, 'pk', ''))
  }, [])

  if (serverData.showErrorPage) {
    return <NotFoundPage />
  }

  if (serverData.storedPassword !== ENV.TTF_ACCESS && serverData.host?.includes('tickets-staging.manacommon.com')) {
    return <AccessControl />
  }

  const {
    name,
    start_date,
    end_date,
    image_full_url,
    description,
    id,
    brand_name,
    brand_url,
    // minimum_age,
    description_plain,
    formatted_date,

    prereg_enabled,
    prereg_started,
    prereg_ended,
    // presale_started,
    // presale_ended,
  } = isPasswordProtected ? event : _get(serverData, 'event.event', {})

  const ticketsData = isPasswordProtected ? tickets : _get(serverData, 'event.tickets', [])

  const { name: venueName, city, country, postal_code, state, street } = isPasswordProtected ? venue : _get(serverData, 'event.venue', {})
  const plainDescription = (description_plain || '').slice(0, 100)
  const metaTitle = `${name} | Mana Common`

  const date = moment(start_date).format('dddd, MMMM D, YYYY')
  const isoStartDate = moment(start_date).format()
  const isoEndDate = moment(end_date).format()
  const endDateUnindex = moment(end_date).format('YYYY-MM-DD')

  const venueFullAddress = `${venueName} , ${city}`
  const dateInfo = formatDate(start_date, end_date, formatted_date)
  const imgURL = image_full_url ? image_full_url : NoDataImg
  // (Temp Hide) const minAgeValue = minimum_age ? `[Ages ${minimum_age} +]` : '[All Ages]'

  const getAuthorizedEvent = async () => {
    setLoading(true)
    try {
      const response = await getEvent(slug)
      setEvent(_get(response, 'event'))
      setVenue(_get(response, 'venue', {}))
      setTickets(_get(response, 'tickets', []))
      setSlug(_get(response, 'slug', ''))
      setPk(_get(response, 'pk', ''))
    } catch (e) {
      console.log('error in getAuthorizedEvent', e)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <Backdrop sx={{ color: '#fff', backgroundColor: 'transparent', zIndex: 1205 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (serverData.error && Object.keys(event).length === 0) {
    if (serverData.message.status === 401) {
      return (
        <Layout>
          <PasswordProtected
            slug={slug || eventSlug}
            pk={pk}
            id={id || eventId}
            getAuthorizedEvent={getAuthorizedEvent}
          />
        </Layout>
      )
    }
    return <Error />
  }

  const handleReserveButtonClick = () => {
    if (isWindowDefined) {
      window.location.href = `/events/${slug || eventSlug}/seat-map?event_id=${id || eventId}`
    }
  }

  // Temp redirection
  if (_get(serverData, 'event.event.skip_details_page', false)) {
    handleReserveButtonClick()
    return null
  }

  return (
    <>
      <Head
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: plainDescription,
          },
          {
            property: `og:description`,
            content: plainDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `twitter:title`,
            content: metaTitle,
          },
          {
            property: `twitter:description`,
            content: plainDescription,
          },
          {
            property: 'og:image',
            content: image_full_url,
          },
          {
            property: 'og:image:width',
            content: '400',
          },
          {
            property: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            property: 'twitter:label1',
            content: 'DATE',
          },
          {
            property: 'twitter:data1',
            content: date,
          },
          {
            property: 'twitter:label2',
            content: 'VENUE',
          },
          {
            property: 'twitter:data2',
            content: venueFullAddress,
          },
          {
            property: 'twitter:site',
            content: '@ManaCommon',
          },
          {
            property: 'twitter:creator',
            content: '@ManaCommon',
          },
          {
            property: 'twitter:domain',
            content: isWindowDefined ? window.location.host : '',
          },
          {
            property: 'og:url',
            content: isWindowDefined ? window.location.host : '',
          },
          {
            property: 'twitter:image:src',
            content: image_full_url,
          },
          {
            property: 'fb:app_id',
            content: '149831345062797',
          },
          {
            property: 'og:type',
            content: 'theticketfairy:event',
          },
          {
            property: 'robots',
            content: `unavailable_after: ${endDateUnindex}`,
          }
        ]}
      >
        <script type='application/ld+json'>
          {`{
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "${name}",
            "startDate": "${isoStartDate}",
            "endDate": "${isoEndDate}",
            "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled",
            "location": {
              "@type": "Place",
              "name": "${venueName}",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${street}",
                "addressLocality": "${city}",
                "postalCode": "${postal_code}",
                "addressRegion": "${state}",
                "addressCountry": "${country}"
              }
            },
            "image": [
              "${image_full_url}"
             ],
            "description": "${(description_plain || "").replaceAll("\n", " ")}",
            "offers": [
              ${ticketsData.map((ticket) => `{
                  "@type": "Offer",
                  "name": "${ticket.name}",
                  "url": "https://tickets.manacommon.com/events/${slug}",
                  "price": "${ticket.base_price}",
                  "priceCurrency": "USD",
                  "availability": "${ticket.sold_out ? "https://schema.org/SoldOut" : "https://schema.org/InStock"}"
                }`
              ).join()}
            ],
            "organizer": {
              "@type": "Organization",
              "name": "${brand_name}",
              "url": "${brand_url}"
            }
          }`}
        </script>
      </Head>
      <Layout>
        <div className="event-details-container" itemScope itemType="https://schema.org/Event">
          <div className="mobile-event-image-container">
            <img
              itemProp="image"
              src={imgURL}
              alt="No Data"
              onError={({ currentTarget }) => {
                currentTarget.setAttribute('style', 'display: none;')
              }}
            />
          </div>
          <div className="info-container">
            <p className="name" itemProp="name">
              {name}
            </p>
            <p className="date-venue">
              <span itemProp="startDate">{dateInfo}</span>
              <span>. </span>
              <span itemProp="location" itemScope itemType="https://schema.org/Place">
                <span itemProp="name">{venueName} </span>
                <span itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                  <span itemProp="addressLocality">{city} </span>
                  {country ? (
                    <meta itemRef="address-microdata" itemProp="addressCountry" content={country.toUpperCase()} />
                  ) : null}
                </span>
              </span>
              {/* (Temp Hide) <span>{minAgeValue}</span> */}
            </p>
            <div className="divider" />
          </div>
          <div className="main-content-container">
            <div className="mn__event-tickets-container">
              {prereg_enabled && !prereg_ended ? null : (
                <TicketsContainer
                  theme="light"
                  eventId={id}
                  handleNotInvitedModalClose={() => { }}
                  handleInvalidLinkModalClose={() => { }}
                  onAddToCartSuccess={({
                    skip_billing_page,
                    event_id,
                    hash,
                    total,
                    hasAddOn,
                  }) =>
                    handleCartSuccessRedirect(
                      skip_billing_page,
                      event_id,
                      hash,
                      total,
                      hasAddOn,
                    )
                  }
                  isPromotionsEnabled={event?.is_promotions_enabled}
                  isAccessCodeEnabled={event?.is_access_code}
                  onLogoutSuccess={() => {
                    if (isWindowDefined) {
                      window.location.href = '/'
                    }
                  }}
                  queryPromoCode={getQueryPromoCode()}
                  hideSessionButtons={true}
                  //onGetTicketsSuccess={() => {}}
                  enableAddOns={true}
                  showGroupNameBlock={true}
                  onReserveButtonClick={handleReserveButtonClick}
                  tableTicketsHeaderComponent={<div className="tickets-container-header">RESERVE TABLES</div>}
                  onPendingVerification={() => {
                    if (isWindowDefined) {
                      window.location.replace(`/verification`)
                    }
                  }}
                />
              )}
              <div className="event-image-container">
                <img
                  itemProp="image"
                  src={imgURL}
                  alt="No Data"
                  onError={({ currentTarget }) => {
                    currentTarget.setAttribute('style', 'display: none;')
                  }}
                />
              </div>
              <ShareButtons name={name} />
              {prereg_enabled && !prereg_ended ? (
                <div className="pre_reg_info">
                  <PreRegistrationInformations classNamePrefix="" eventId={id} />
                </div>
              ) : null}
            </div>
            <div>
              {prereg_enabled && !prereg_ended ? (
                <>
                  <PreRegistration
                    isPreregistrationStarted={prereg_started}
                    eventId={id}
                    logo={Logo}
                    showForgotPasswordButton={true}
                    additionalFieldAttribute={{
                      'basic-info-logged-in': {
                        groupLabelVars: [`<b>${name}</b>`],
                      },
                    }}
                    onLoginSuccess={onLoginSuccess}
                    onConfirmationSuccess={(confirmationData) =>
                      handlePreRegistrationCompleteRedirect(confirmationData, id)
                    }
                  />
                  <hr style={{ borderColor: 'black', margin: '10px 30px' }} />
                </>
              ) : null}
              <div
                itemProp="description"
                className="description-container"
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export async function getServerData(props) {
  const cookies = props.headers.get('cookie')
  let storedPassword = ''
  if (cookies) {
    storedPassword = cookie.parse(cookies)?.['ttf-access'] || ''
  }
  const {
    params: { slug },
    query: { pk, eventId },
  } = props

  try {
    const event = await getEvent(slug, pk)
    const { end_date } = event.event

    const endDateUnindex = moment(end_date).add(2, "days")
    const isAfterEndDate = moment().isAfter(endDateUnindex)

    if (isAfterEndDate) {
      return {
        status: 404,
        props: {
          showErrorPage: true
        }
      }
    }

    return {
      props: {
        event,
        slug,
        pk,
        eventId,
        storedPassword,
        host: props.headers.get('host'),
      },
    }
  } catch (error) {
    return {
      props: { error: true, message: error, slug, pk, eventId: error?.response?.data?.event_id },
    }
  }
}

export default EventPage

import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, meta, children }) => {
  return (
    <>
      <Helmet
        defaultTitle="Mana Common Tickets"
        title={title}
        meta={meta}
      >
        {children}
      </Helmet>
    </>
  )
}

export default Head;

import React from 'react'

import AccessControl from './src/components/access/AccessControl'
import { getCookieByName } from './src/utils/cookies'
import { ENV } from './src/constants/env'
import _get from "lodash/get";
import RedirectExternalSite from "./src/components/access/RedirectExternalSite";
import EventPage from "./src/pages/events/[slug]";

export const wrapPageElement = ({element, props}) => {
  const isWindowDefined = typeof window !== 'undefined'
  const storedPassword = getCookieByName('ttf-access')
  const elementType = _get(element, 'type.name')

  if (elementType === EventPage.name) {
    const externalUrl = _get(props, 'serverData.event.event.redirect_url')
    if (externalUrl) {
      return <RedirectExternalSite externalUrl={externalUrl} />;
    }
  }

  if (storedPassword === ENV.TTF_ACCESS) {
    return
  }

  if (isWindowDefined && window.location.hostname === 'tickets-staging.manacommon.com')
    return <AccessControl />
}

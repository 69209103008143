import React, { useState } from 'react'
import { setCustomCookie } from '../../utils/cookies'
import './styles.scss'

const AccessControl = () => {
  const [password, setPassword] = useState('')

  const onSubmit = (event) => {
    const isWindowDefined = typeof window !== 'undefined'
    event.preventDefault()
    setCustomCookie('ttf-access', password, 2)
    isWindowDefined && window.location.reload()
  }
  return (
    <div className='access-container'>
      <h1>Welcome</h1>
      <form onSubmit={onSubmit}>
        <input
          name='password'
          type='password'
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button type='submit'>Enter</button>
      </form>
    </div>
  )
}

export default AccessControl

import React, { useState } from 'react'
import { getProtectedEvent } from '../../http_service/events'

const PasswordProtected = ({
  slug = '',
  getAuthorizedEvent,
  pk = '',
  id = '',
}) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const passwordHasValue = !!password.trim()

  const onSubmit = async () => {
    try {
      const response = await getProtectedEvent(password, id)
      if (response.status === 200) {
        setPassword('')
        getAuthorizedEvent(slug)
      }
    } catch (e) {
      setError(e?.response?.data?.message || '')
    }
  }

  return (
    <div className="password-protected">
      <div className="password-protected-block">
        <h1 className="password-protected-text">This content is restricted</h1>
        <h3 className="password-protected-text">
          Please enter the event password.
        </h3>
      </div>
      <input
        className="password-protected-input"
        placeholder=""
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && passwordHasValue) {
            onSubmit(password)
          }
        }}
        type="password"
      />
      <p
        className="error-block"
        style={{ display: Boolean(error) ? 'flex' : 'none' }}
      >
        {error}
      </p>
      <button
        className="password-submit-button"
        onClick={() => {
          if (passwordHasValue) {
            onSubmit(password)
          }
        }}
      >
        ENTER
      </button>
    </div>
  )
}

export default PasswordProtected

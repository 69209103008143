import React from 'react'
import FooterImage from '../../assets/images/mana_footer.png'
import Head from '../head/index';
import Layout from '../layout/index';
import './style.scss'

const Error = () => {
  return (
    <div className='error-page'>
      <Head title='Mana Common' />
      <Layout>
        <main>
          <h1>Oops! Something isn't quite right 🚧</h1>
          <p>
            We're sorry, something went wrong. Please{' '}
            <a href='https://support.theticketfairy.com/'>
              contact customer support
            </a>
            .
          </p>
          <p>
            <img src={FooterImage} alt="" />
          </p>
        </main>
      </Layout>
    </div>
  )
}

export default Error

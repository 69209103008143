export function getDomain(url, subdomain) {
  let updatedUrl = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (!subdomain) {
    updatedUrl = updatedUrl.split('.')

    updatedUrl = updatedUrl.slice(updatedUrl.length - 2).join('.')
  }

  if (updatedUrl.indexOf('/') !== -1) {
    return updatedUrl.split('/')[0]
  }

  return updatedUrl
}

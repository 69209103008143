import React, {useEffect} from 'react'

const RedirectExternalSite = ({externalUrl}) => {

  useEffect(_ => {
    if (window.location.replace !== undefined) {
      window.location.replace(externalUrl)
    } else {
      window.location.href = externalUrl
    }
  }, [])

  return <></>;
}

export default RedirectExternalSite
